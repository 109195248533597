import { INodeItem } from '../../interfaces/workflow-form-interface';

const nodes: Record<string, INodeItem> = {
  'entrypoint-398705a7-5bbe-4f53-964a-7fb6b91dd000': {
    key: 'entrypoint-398705a7-5bbe-4f53-964a-7fb6b91dd000',
    position: {
      top: 102,
      left: 110,
    },
    outputs: ['op_entrypoint-398705a7-5bbe-4f53-964a-7fb6b91dd000'],
    type: 'ENTRYPOINT',
    configs: {
      name: 'entrypoint',
    },
    data: {},
    inputs: [],
  },
};

export default nodes;
