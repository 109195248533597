export enum NodeGroupType {
  Dag = 'dag',
  Steps = 'steps',
  Controls = 'controls',
}

export enum NodeTypes {
  TEMPLATE = 'TEMPLATE',
  GROUP = 'GROUP',
  ENTRYPOINT = 'ENTRYPOINT',
}
