import axiosHelper from '../helper/axiosHelper';
import * as env from '../../env';
import { IContextRepo, IContributor, IService } from '../interfaces/interfaces';
import { IApplication } from '../interfaces/argocd-interface';

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
};

const serverUrl = env.httpProtocol.concat(
  env.serverHost,
  ':',
  env.serverPort,
  '/namespace',
);

export const getNamespaceInfoAPI = async () => {
  const url = serverUrl.concat(
    '/namespace');
  const result = await axiosHelper.axiosGet(url, paramHeaders);

  return result;
};

export const getAllNamespaceInfoAPI = async () => {
  const url = serverUrl.concat('/service/all');
  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const getMyWorkspaceContributorsAPI = async () => {
  const url = serverUrl + '/get-contributors';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};
export const getWorkspaceImContributorOnAPI = async () => {
  const url = serverUrl + '/get-contributor-on';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const deleteServiceAPI = async (applicationName: string) => {
  const url = serverUrl + '/delete-service';
  return axiosHelper.axiosPost(url, {name: applicationName}, paramHeaders);
};

export const addNamespaceContributorsAPI = async (
  contributor: IContributor,
) => {
  const url = serverUrl + '/add-contributors';
  return axiosHelper.axiosPost(url, contributor, paramHeaders);
};

export const deleteNamespaceContributorAPI = async (email: string) => {
  const url = serverUrl + '/delete-contributor';
  return axiosHelper.axiosPost(url, { email }, paramHeaders);
};
export const leaveNamespaceAsContributorAPI = async (
  email: string,
  namespace: string,
) => {
  const url = serverUrl + '/leave-as-contributor';
  return axiosHelper.axiosPost(url, { email, namespace }, paramHeaders);
};

export const getWorkspacesAPI = async () => {
  const url = serverUrl + '/get-workspaces';
  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const changeSessionNamespaceAPI = async (namespace: string) => {
  const url = serverUrl + '/change-namespace';
  return axiosHelper.axiosPost(url, { namespace }, paramHeaders);
};

export const addOrUpdateNamespaceAPI = async (name: string) => {
  const url = serverUrl + '/add-namespace';
  return axiosHelper.axiosPost(url, { name }, paramHeaders);
};

export const updateNamespaceAPI = async (
  service: IApplication,
) => {
  const url = serverUrl + '/update-namespace';
  return axiosHelper.axiosPost(
    url,
    service,
    paramHeaders,
  );
};

export const deleteNamespaceAPI = async (code: string) => {
  const url = serverUrl + '/delete-namespace';
  return axiosHelper.axiosPost(url, { code }, paramHeaders);
};

export const getNamespaceServicesAPI = async () => {
  const url = serverUrl + '/services';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const addContextRepoAPI = async (contextRepo: IContextRepo) => {
  const url = serverUrl + '/add-context-repo';

  const result = await axiosHelper.axiosPost(url, contextRepo, paramHeaders);
  return result;
};

export const getContextReposAPI = async () => {
  const url = serverUrl + '/context-repos';

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const deleteContextReposAPI = async (contextRepoName: string) => {
  const url = serverUrl + '/delete-context-repo';

  const result = await axiosHelper.axiosPost(
    url,
    { contextRepoName: contextRepoName },
    paramHeaders,
  );
  return result;
};

export const serviceDecryptKeyAPI = async (key: string, idObject: number) => {
  const url = serverUrl + '/decrypted-key';

  const result = await axiosHelper.axiosPost(
    url,
    { key, idObject },
    paramHeaders,
  );
  return result;
};
