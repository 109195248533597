import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; 
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';

import userReducer from '../src/slices/user';
import messageReducer from '../src/slices/message';
import serviceReducer from '../src/slices/services';
import workspaceReducer from '../src/slices/workspaces';
import productReducer from '../src/slices/product';
import planReducer from '../src/slices/plan';
import subscriptionReducer from '../src/slices/subscription';
import bankInfoReducer from '../src/slices/bankInfo';
import orderReducer from '../src/slices/order';
import pipelineReducer from '../src/slices/pipeline';
import providerReducer from '../src/slices/gitprovider';


// Persist config
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['provider'],
};

// Combine reducers
const rootReducer = combineReducers({
  user: userReducer,
  message: messageReducer,
  service: serviceReducer,
  workspace: workspaceReducer,
  product: productReducer,
  plan: planReducer,
  subscription: subscriptionReducer,
  bankinfo: bankInfoReducer,
  order: orderReducer,
  pipeline: pipelineReducer,
  provider: providerReducer,
});

// Wrap root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

// Persistor
export const persistor = persistStore(store);

export default store;
