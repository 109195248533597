import axiosHelper from '../helper/axiosHelper';
import * as env from '../../env';

const paramHeaders = {
  headers: {
    Accept: 'application/json',
  },
  withCredentials: true,
};

const paramHeadersFormData = {
  headers: {
    Accept: 'application/json',
    'Content-type': 'multipart/form-data',
  },
  withCredentials: true,
};

const serverUrl = env.httpProtocol.concat(
  env.serverHost,
  ':',
  env.serverPort,
  '/core',
);

export const generateAdminAPIKey = async (apiId: any) => {
  const url = serverUrl.concat('/admin-api-key');

  const result = await axiosHelper.axiosPost(
    url,
    { apiId: apiId },
    paramHeaders,
  );
  return result;
};

export const getAdminAPIKey = async () => {
  const url = serverUrl.concat('/admin-api-key');

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const generateUserAPIKey = async (apiId: any) => {
  const url = serverUrl.concat('/user-api-key');

  const result = await axiosHelper.axiosPost(
    url,
    { apiId: apiId },
    paramHeaders,
  );
  return result;
};

export const getUserAPIKey = async () => {
  const url = serverUrl.concat('/user-api-key');

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const getUserAPI = async () => {
  const url = serverUrl.concat('/user');

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const patchUserAPI = async (data: void) => {
  const url = serverUrl.concat('/user');
  const result = await axiosHelper.axiosPatch(url, data, paramHeaders);
  return result;
};

export const photoUserAPI = async (data: void) => {
  const url = serverUrl.concat('/photo-user');
  const result = await axiosHelper.axiosPost(url, data, paramHeadersFormData);
  return result;
};

export const updateLocaleUserAPI = async (data: any) => {
  const url = serverUrl.concat('/locale-user');
  const result = await axiosHelper.axiosPost(url, data, paramHeaders);
  return result;
};

export const userSendMailAPI = async (data: {
  to: string;
  subject: string;
  message: string;
}) => {
  const url = serverUrl.concat('/user-send-mail');

  const result = await axiosHelper.axiosPost(url, data, paramHeaders);
  return result;
};

export const bulkSendMailAPI = async (data: any) => {
  const url = serverUrl.concat('/bulk-send-mail');

  const result = await axiosHelper.axiosPost(url, data, paramHeaders);
  return result;
};

export const getUsersAPI = async () => {
  const url = serverUrl.concat('/list-all-users');

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const saveUserAPI = async (data: any) => {
  const url = serverUrl;

  const result = await axiosHelper.axiosPut(url, data, paramHeaders);
  return result;
};

export const updateUserAPI = async (data: any) => {
  const url = serverUrl.concat('/update-user');
  const result = await axiosHelper.axiosPatch(url, data, paramHeaders);
  return result;
};

export const deleteUserAccountAPI = async () => {
  const url = serverUrl.concat('/user');

  const result = await axiosHelper.axiosDelete(url, paramHeaders);
  return result;
};

export const getUserProjects = async () => {
  const url = serverUrl.concat('/projects');

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const getSessionSettingsAPI = async () => {
  const url = serverUrl.concat('/session-settings');

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};

export const getLogsAPI = async (type: string) => {
  const url = serverUrl.concat('/logs/', type);

  const result = await axiosHelper.axiosGet(url, paramHeaders);
  return result;
};
